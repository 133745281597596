.mobile-viewport-only {
    display: none;
}

button[disabled] {
    background-color: var(--grey-color) !important;
    cursor: pointer !important;
    border: 1px solid var(--grey-color) !important;
}

h2 {
    font-size: var(--subheading-text);
}

.contact-heading-container>h1 {
    font-family: Gilroy;
    font-weight: 600;
    font-size: 6rem;

    color: #fff;

    margin: 1em 0 0 0;
}

.contact-heading-container>h3 {
    font-family: Gilroy;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px !important;
    letter-spacing: .15em !important;
    line-height: 1.5em;
    color: #fff;

    margin-bottom: 1em;
}

.contact-heading-container {
    margin: 100px 0px;
    text-align: center;

    min-width: 100%;
}

.contact-heading-wrapper {
    min-width: 100%;
    background-color: var(--complimentary-theme-color);

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.contact-us-text {
    font-size: var(--main-text);
}

.contact-us-text p:not(:first-of-type) {
    margin-top: 1.25em;
}

#help-center-link {
    color: var(--main-theme-color);
}

.contact-form-container {
    margin-top: 70px;
}

.contact-form-container > h2 {
    font-size: var(--subheading-text);
}

.contact-form-section {
    margin-bottom: 100px;
}

.contact-form-dropdown {
    margin-top: 30px;
    position: relative;
}

.contact-form-dropdown>div {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.contact-form-dropdown>button {
    border: none;
    margin: 0;
    width: auto;
    overflow: visible;
    background-color: transparent;
    color: inherit;
    font: inherit;
    line-height: 1.15;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    border-radius: 4px;

    cursor: pointer;
    display: inline-block;
    font-size: .95rem;
    font-weight: 500;
    padding: 12px 16px;
    transition: all 80ms ease-in-out 0s;
    background-color: var(--main-theme-color);
    border: 1px solid var(--main-theme-color);
    color: #fff;
}

.error {
    margin-left: 5px;
    font-size: 13px;
    color: #df5f67;
}

[hidden] {
    display: none !important;
}

label {
    font-size: var(--main-text) !important;
    align-self: flex-start;
    color: #050f19;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4285714286;
    margin-bottom: 4px;
    max-width: 450px;
    padding-right: 0;
    padding-top: 0;
    text-align: left;
    width: 90%;
}

textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1rem;
    height: 56px;
    padding-right: 24px;
    transition: height 0.4s ease-in-out;

    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #b7c2cc;
    color: #050f19;
    display: block;
    font-size: 1rem;
    line-height: 1.15;
    padding: 15px 16px;
    width: 100%;
    resize: none;
}

textarea:focus {
    height: 150px;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1rem;
    height: 56px;
    padding-right: 24px;
    transition: height 0.4s ease-in-out;

    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #b7c2cc;
    color: #050f19;
    display: block;
    font-size: 1rem;
    line-height: 1.15;
    padding: 15px 16px;
    width: 100%;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.966 5.602a.5.5 0 01-.734 0L.525 1.59A.5.5 0 01.892.75h7.414a.5.5 0 01.368.84L4.967 5.601z' fill='%23050F19'/%3E%3C/svg%3E");
    background-position-x: calc(100% - 10px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    font-size: 1rem;
    height: 56px;
    padding-right: 24px;

    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #b7c2cc;
    color: #050f19;
    display: block;
    font-size: 1rem;
    line-height: 1.15;
    padding: 15px 16px;
    width: 100%;
}

option {
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
    padding: 0px 2px 1px;
}

.contact-us-sidebar {
    margin: 0 0 30px 50px;
}

.phone-support {
    margin-top: 20px;
}

.phone-support-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid rgba(91, 99, 110, .2);
    box-sizing: border-box;
    border-radius: 8px;
}

.phone-support-container>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
    grid-gap: 14px;
}

figure {
    margin: 0;
    margin-top: 5%;
}

.phone-support-list {
    border-top: 1px solid rgba(91, 99, 110, .2);
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.phone-support-list-item {
    align-items: center;
    display: flex;
    flex-basis: 255px;
    padding: 16px 24px;
}

.phone-support-list-item::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuMDg0MjEgNS43Njk4OUM1Ljk1Mzk2IDcuODE4OTMgNy44MzkyNiAxMC42NDYgMTEuMDIxNSAxMi4wMTY2QzExLjY0MTcgMTEuNTQzMiAxMi40MTg4IDEwLjk1MzggMTIuOTA5NyAxMC41OTUzQzE0LjI1MjcgMTAuNzM3OSAxNC41NDA5IDEwLjc3MzYgMTUuMDg0OSAxMC44NDFDMTUuNDU0NCAxMC44ODY3IDE1Ljk0MiAxMC45NDcxIDE2Ljk1ODMgMTEuMDY1NlYxNS45NzI4QzE0LjgyNjYgMTYuMTAxIDEyLjY5MTkgMTUuNzc1NiAxMC42OTUzIDE1LjAxODFDOC42OTg3MiAxNC4yNjA3IDYuODg1NyAxMy4wODgzIDUuMzc2MDQgMTEuNTc4NkMzLjg2NjM3IDEwLjA2ODkgMi42OTQ0NCA4LjI1NjE5IDEuOTM3NjggNi4yNjAyNEMxLjE4MDkzIDQuMjY0MjggMC44NTY1NzEgMi4xMzA1NCAwLjk4NjA1IDBINS45MDQ2OEM2LjE2NzIxIDIuMzMyMzcgNi4xOTk3MyAyLjU5NTc0IDYuMjQ5MSAyLjk5NTU3QzYuMjc0ODYgMy4yMDQyMiA2LjMwNTIxIDMuNDUwMDQgNi4zNzUyNCA0LjA0NjQzQzUuOTQwMTcgNC42NDI4OCA1LjQ3MzM5IDUuMjYxMDMgNS4wODQyMSA1Ljc2OTg5WiIgZmlsbD0iIzVCNjE2RSIvPgo8L3N2Zz4K");
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 1px solid rgba(91, 99, 110, .2);
    box-sizing: content-box;
    content: "";
    display: block;
    height: 16px;
    margin-right: 16px;
    padding: 8px;
    width: 16px;
}

.email-contact {
    font-size: 1.3rem;
    color: var(--main-theme-color);
}


.phone-support-text:first-child {
    margin-top: 0;
}

.la-mobile {
    font-size: 80px;
}

.phone-support-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px 0 0;
}

.phone-support-list-item-text {
    color: var(--midnight-blue-color);
    font-size: 16px;
}

.phone-support-list-item-text>span {
    display: block;   
}

.phone-support-list-item-text>a {
    color: var(--main-theme-color);
    font-weight: 500;
}

@media (min-width: 939px) {
    .two-column-layout {
        display: flex;
    }

    .two-column-layout .main-col {
        max-width: 67%;
        width: 100%;
    }

    .two-column-layout .side-col {
        max-width: 32%;
    }
}

/* Mobile Displays */
@media screen and (max-width: 938px) {
    .mobile-viewport-only {
        margin-top: 40px;
        display: block;
    }

    .phone-support {
        margin-top: 10px;
    }

    .two-column-layout .main-col {
        width: 100%;
    }

    .two-column-layout .side-col {
        width: 100%;
    }

    .contact-us-sidebar {
        margin: 0;
    }

    .la-mobile {
        font-size: 50px;
    }

    .phone-support-text>figure {
        display: none;
    }

    .two-column-layout {
        display: block;
        width: 100%;
    }

    .side-col {
        width: 100%;
    }

    .contact-form-dropdown>button {
        width: 100%;
    }
}