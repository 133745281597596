.slideItem {
  /*margin-left: 30px;*/
  margin-right: 30px;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  height: 100px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.slick-prev:before {
  color: black !important;
}

.slick-next:before {
  color: black !important;
}

.partner-logo {
  width: 90px;
  height: auto;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.partner-logo:hover {
  -webkit-filter: none;
          filter: none;
}
