#footer-section {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    max-width: 1180px;
    margin: 0px auto;
    padding: 100px 24px;
    color: rgb(var(--midnight-blue-color));
}

.footer-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
    background: rgb(var(--midnight-blue-color));
}

.footer-company-section {
    padding: 0px 4px;
    margin-bottom: 30px;
}

.footer-company-section-wrapper {
    max-width: 240px;
}

.footer-company-section-wrapper>img {
    display: block;
    margin-bottom: 32px;
    height: 20px;
    width: 112px;
}

.footer-company-section-company-details {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.copyright-details {
    font-size: 16px;
    line-height: 1.3;
    opacity: 0.7;
    color: rgb(17, 51, 85);
    text-decoration: none;
    max-width: 240px;
    margin-top: 0px;
    margin-bottom: 20px;
}


.social-buttons-container {
    display: flex;
    flex-direction: row;
}

.social-button {
    font-size: 20px;
    line-height: 1.3;
    opacity: 0.7;
    color: black;
    text-decoration: none;
    max-width: 200px;
}

.social-button:hover {
    transform: scale(1.3);
}

.social-button:not(:first-of-type) {
    margin-left: 20px;
}

.la-phone-volume:hover {
    color: #05c46b;
}

.la-facebook {
    font-size: 22px;
    margin-left: -3px !important;
    margin-top: -5%;
}

.la-facebook:hover {
    color: #3b5998;
}

.la-at:hover {
    color: #0fbcf9;
}

.la-instagram {
    font-size: 22px;
    margin-left: -3px !important;
    margin-top: -5%;
}

.la-instagram:hover {
    color: #f53b57;
}

.la-map:hover {
    color: #f9ca24;
}


.footer-links-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.footer-links-section-container {
    padding: 0px 4px;
    margin-bottom: 30px;
}

.footer-links {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
}

.footer-links li {
    margin-bottom: 15px;
}

.footer-links li>a {
    font-size: 16px;
    line-height: 1.3;
    opacity: 0.7;
    color: rgb(17, 51, 85);
    text-decoration: none;
    max-width: 200px;
}

.footer-links li>a:hover {
    color: var(--main-theme-color);
}


/* Mobile Displays */
@media screen and (max-width: 940px) {
    .footer-wrapper {
        grid-template-columns: 1fr;
    }


    .social-button:not(:first-of-type) {
        margin-left: 45px;
    }

    .footer-links-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .la-instagram,
    .la-facebook {
        margin-left: 0px !important;
    }
}