.service-page-container {
    h1 {
        text-transform: capitalize;
    }

    .service-description {
        font-size: 2rem;
    }

    .section-heading {
        font-size: 1.8rem;
        margin-top: 4%;
    }

    .section-text {
        font-size: 1.3rem;
    }

    .section-bulletpoints {
        font-size: 1.3rem;
        margin-left: 1%;

        li {
            margin-top: 10px;
        }
    }

    .body-button {
        margin-top: 2%;
    }

    .service-content {
        display: flex;
        justify-content: space-between;

        .left-content {
            flex: 1;
        }

        .right-content {
            flex: 1;
        }

        .image-ul-container {
            display: flex;
            align-items: center; // Vertically center the content

            .section-bulletpoints {
                flex: 1; // Take up remaining space on the left
            }

            .service-image {
                width: 35%; // Adjust the width as needed
                height: auto; // Maintain aspect ratio
                margin: 0 10%; // Add margin around the image

                box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.35);
            }
        }
    }
}