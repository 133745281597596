.course-page-wrapper {
    padding: 40px 0;

    .course-page {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;

        .course-page-heading {
            text-align: left;
            font-size: 55px;
            font-weight: 500;
            margin-left: 40px;
            margin-bottom: 40px;
        }

        .main-body {
            display: flex;
            justify-content: space-between;
            padding: 15px 20px;

            .left-body-content {
                width: 70%;
                padding: 20px;
                padding-top: 0px;
                padding-right: 10%;
                box-sizing: border-box;

                h3 {
                    font-size: 1.7rem;
                }

                p {
                    font-size: 1.2rem;
                    padding-bottom: 16px;
                }

                ul {
                    padding-left: 20px;
                }
            }

            .right-body-content {
                width: 30%;
                padding: 20px;
                margin-top: 5%;
                box-sizing: border-box;
                height: 90%;
            }
        }
    }

    .next-steps-div {
        margin-left: 40px;
        margin-bottom: 40px;
        padding-top: 1%;
        padding-bottom: 2%;
        padding-left: 3%;
        padding-right: 3%;

        background: linear-gradient(135deg, #00707E, #4B9EB1, #E5D9F2, #F5EFFF);
        background-size: 400% 400%;
        animation: gradient-animation 8s ease infinite;
        border-radius: 11px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);

        h3 {
            color: black;
        }

        p {
            color: black;
        }

        .button-container {
            display: flex;
            justify-content: start;
            margin-top: 20px;

            .contact-button {
                text-align: center;
                width: 45%;
                padding: 15px 20px;
                background-color: #262626;
                color: white;
                font-weight: bold;
                font-size: 1.3rem;
                border-radius: 11px;
                text-decoration: none;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: darken(white, 80%);
                }
            }
        }
    }

    @keyframes gradient-animation {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

}