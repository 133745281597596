.service-page-container h1 {
  text-transform: capitalize;
}

.service-page-container .service-description {
  font-size: 2rem;
}

.service-page-container .section-heading {
  font-size: 1.8rem;
  margin-top: 4%;
}

.service-page-container .section-text {
  font-size: 1.3rem;
}

.service-page-container .section-bulletpoints {
  font-size: 1.3rem;
  margin-left: 1%;
}

.service-page-container .section-bulletpoints li {
  margin-top: 10px;
}

.service-page-container .body-button {
  margin-top: 2%;
}

.service-page-container .service-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.service-page-container .service-content .left-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.service-page-container .service-content .right-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.service-page-container .service-content .image-ul-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.service-page-container .service-content .image-ul-container .section-bulletpoints {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.service-page-container .service-content .image-ul-container .service-image {
  width: 35%;
  height: auto;
  margin: 0 10%;
  -webkit-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.35);
          box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.35);
}
