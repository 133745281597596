@mixin link-border-slide($color, $hoverColor, $borderWidth, $time) {
    $easing: cubic-bezier(0.4, 0, 0.2, 1);

    position: relative;

    display: inline-block;
    padding: 0.5em 2em;

    cursor: pointer;
    overflow: hidden;

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 0;

        height: $borderWidth;
        width: 100%;

        background-color: $color;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    &:hover {

        &>* {

            &>* {

                &:before,
                &:after {
                    transform: translate3d(0, 0, 0);
                }

                &>* {

                    &:before,
                    &:after {
                        transform: translate3d(0, 0, 0);
                    }
                }
            }
        }
    }

    &>* {

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;

            height: 100%;
            width: $borderWidth;

            background-color: $color;
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }

        &>* {

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                z-index: 9;

                height: $borderWidth;
                width: 100%;

                background-color: $hoverColor;
            }

            &:before {
                top: 0;

                transform: translate3d(-105%, 0, 0);
                transition: transform $time $easing;
            }

            &:after {
                bottom: 0;

                transform: translate3d(105%, 0, 0);
                transition: transform $time $easing;
            }

            &>* {

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    z-index: 9;

                    height: 100%;
                    width: $borderWidth;

                    background-color: $hoverColor;
                }

                &:before {
                    left: 0;

                    transform: translate3d(0, 105%, 0);
                    transition: transform $time $easing;
                }

                &:after {
                    right: 0;

                    transform: translate3d(0, -105%, 0);
                    transition: transform $time $easing;
                }
            }
        }
    }
}

// Styling 

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.main-body-heading {
    padding-bottom: 48px !important;
}

.news-year-header {
    width: 100%;
    font-size: 26px;
    text-align: center;
    color: var(--main-theme-color);
    margin-top: 5%;

    hr {
        border: 0;
        height: 1px;
        background: var(--secondary-theme-color);
        background-image: linear-gradient(to right, #ccc, var(--secondary-theme-color), #ccc);
    }
}

.news-section-container {
    margin-top: 4em;
    display: flex;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: space-evenly;
    align-items: stretch
}

.news-section-item {
    @include link-border-slide(#dcdde1, var(--secondary-theme-color), 2px, 0.7s);

    position: relative;
    width: 25%;
    margin: 20px;
    margin-bottom: 2em;
    padding-bottom: 4em;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    color: #000;
    padding-top: 2em;


    &:hover {
        background-color: #dcdde1;
        -moz-transition: all .7s ease-in;
        -o-transition: all .7s ease-in;
        -webkit-transition: all .7s ease-in;
        transition: all .7s ease-in;

        span>h1 {
            -moz-transition: all .3s ease-in;
            -o-transition: all .3s ease-in;
            -webkit-transition: all .3s ease-in;
            transition: all .3s ease-in;

            color: var(--main-theme-color);
            text-decoration: underline;
        }
    }
}

.news-section-item-picture {
    margin-bottom: 1em;
    display: block;
    width: 100%;
    height: auto;
}

.news-section-item-text {
    h1 {
        font-size: 24px;
        letter-spacing: 0;
        width: 100% !important;
        max-width: 100% !important;
        line-height: 1.2em;
        margin: 0;
        text-transform: capitalize;
    }

    h3 {
        margin-top: 1em;
        opacity: .5;
    }
}

@media screen and (max-width: 940px) {
    .main-body-heading {
        text-align: center;
    }

    .news-section-item {
        @include link-border-slide(transparent, transparent, 0px, 0);

        width: 100%;
    }
}