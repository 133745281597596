.fade-in-section {
  opacity: 0;
  -webkit-transform: translateY(20vh);
          transform: translateY(20vh);
  visibility: hidden;
  -webkit-transition: opacity 0.6s ease-out, -webkit-transform 1.2s ease-out;
  transition: opacity 0.6s ease-out, -webkit-transform 1.2s ease-out;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out, -webkit-transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  visibility: visible;
}

.main-body-heading {
  padding-bottom: 48px !important;
}

.news-year-header {
  width: 100%;
  font-size: 26px;
  text-align: center;
  color: var(--main-theme-color);
  margin-top: 5%;
}

.news-year-header hr {
  border: 0;
  height: 1px;
  background: var(--secondary-theme-color);
  background-image: -webkit-gradient(linear, left top, right top, from(#ccc), color-stop(var(--secondary-theme-color)), to(#ccc));
  background-image: linear-gradient(to right, #ccc, var(--secondary-theme-color), #ccc);
}

.news-section-container {
  margin-top: 4em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.news-section-item {
  position: relative;
  display: inline-block;
  padding: 0.5em 2em;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 25%;
  margin: 20px;
  margin-bottom: 2em;
  padding-bottom: 4em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #000;
  padding-top: 2em;
}

.news-section-item:before, .news-section-item:after {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #dcdde1;
}

.news-section-item:before {
  top: 0;
}

.news-section-item:after {
  bottom: 0;
}

.news-section-item:hover > * > *:before, .news-section-item:hover > * > *:after {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.news-section-item:hover > * > * > *:before, .news-section-item:hover > * > * > *:after {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.news-section-item > *:before, .news-section-item > *:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #dcdde1;
}

.news-section-item > *:before {
  left: 0;
}

.news-section-item > *:after {
  right: 0;
}

.news-section-item > * > *:before, .news-section-item > * > *:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 9;
  height: 2px;
  width: 100%;
  background-color: var(--secondary-theme-color);
}

.news-section-item > * > *:before {
  top: 0;
  -webkit-transform: translate3d(-105%, 0, 0);
          transform: translate3d(-105%, 0, 0);
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
}

.news-section-item > * > *:after {
  bottom: 0;
  -webkit-transform: translate3d(105%, 0, 0);
          transform: translate3d(105%, 0, 0);
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
}

.news-section-item > * > * > *:before, .news-section-item > * > * > *:after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 9;
  height: 100%;
  width: 2px;
  background-color: var(--secondary-theme-color);
}

.news-section-item > * > * > *:before {
  left: 0;
  -webkit-transform: translate3d(0, 105%, 0);
          transform: translate3d(0, 105%, 0);
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
}

.news-section-item > * > * > *:after {
  right: 0;
  -webkit-transform: translate3d(0, -105%, 0);
          transform: translate3d(0, -105%, 0);
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
}

.news-section-item:hover {
  background-color: #dcdde1;
  -webkit-transition: all .7s ease-in;
  transition: all .7s ease-in;
}

.news-section-item:hover span > h1 {
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  color: var(--main-theme-color);
  text-decoration: underline;
}

.news-section-item-picture {
  margin-bottom: 1em;
  display: block;
  width: 100%;
  height: auto;
}

.news-section-item-text h1 {
  font-size: 24px;
  letter-spacing: 0;
  width: 100% !important;
  max-width: 100% !important;
  line-height: 1.2em;
  margin: 0;
  text-transform: capitalize;
}

.news-section-item-text h3 {
  margin-top: 1em;
  opacity: .5;
}

@media screen and (max-width: 940px) {
  .main-body-heading {
    text-align: center;
  }
  .news-section-item {
    position: relative;
    display: inline-block;
    padding: 0.5em 2em;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
  }
  .news-section-item:before, .news-section-item:after {
    content: "";
    position: absolute;
    left: 0;
    height: 0px;
    width: 100%;
    background-color: transparent;
  }
  .news-section-item:before {
    top: 0;
  }
  .news-section-item:after {
    bottom: 0;
  }
  .news-section-item:hover > * > *:before, .news-section-item:hover > * > *:after {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .news-section-item:hover > * > * > *:before, .news-section-item:hover > * > * > *:after {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .news-section-item > *:before, .news-section-item > *:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 0px;
    background-color: transparent;
  }
  .news-section-item > *:before {
    left: 0;
  }
  .news-section-item > *:after {
    right: 0;
  }
  .news-section-item > * > *:before, .news-section-item > * > *:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 9;
    height: 0px;
    width: 100%;
    background-color: transparent;
  }
  .news-section-item > * > *:before {
    top: 0;
    -webkit-transform: translate3d(-105%, 0, 0);
            transform: translate3d(-105%, 0, 0);
    -webkit-transition: -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0 cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
  }
  .news-section-item > * > *:after {
    bottom: 0;
    -webkit-transform: translate3d(105%, 0, 0);
            transform: translate3d(105%, 0, 0);
    -webkit-transition: -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0 cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
  }
  .news-section-item > * > * > *:before, .news-section-item > * > * > *:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 9;
    height: 100%;
    width: 0px;
    background-color: transparent;
  }
  .news-section-item > * > * > *:before {
    left: 0;
    -webkit-transform: translate3d(0, 105%, 0);
            transform: translate3d(0, 105%, 0);
    -webkit-transition: -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0 cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
  }
  .news-section-item > * > * > *:after {
    right: 0;
    -webkit-transform: translate3d(0, -105%, 0);
            transform: translate3d(0, -105%, 0);
    -webkit-transition: -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0 cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0 cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0 cubic-bezier(0.4, 0, 0.2, 1);
  }
}
