/* Fonts */
@font-face {
    font-family: navbar-font;
    src: url("../../fonts/Roobert/Roobert-Regular.woff");
    font-weight: normal;
}

@font-face {
    font-family: navbar-font;
    src: url("../../fonts/Roobert/Roobert-Light.woff");
    font-weight: lighter;
}


path {
    fill: var(--main-theme-color);
}

.NavbarItems {
    box-sizing: border-box;
    background: var(--navbar-bg-color);
    border-top: 5px solid var(--main-theme-color);
    border-bottom: 1px solid #eae7e2;

    font-family: navbar-font;

    display: flex;
    justify-content: space-between;
    height: 80px;
    width: 100vw;
    padding: 0px 30px 8px 0px;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    width: 65px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 1px;
    margin-left: 30px;

    justify-self: start;
    cursor: pointer;
}

.NavbarItems ul {
    display: block;
    list-style-type: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

ul {
    padding-left: 0;
    margin-left: 0;
}

ul li {
    max-width: 35em;
}

a {
    text-decoration: none;
    font-family: inherit;
    padding-bottom: .1em;
    transition: all 0.1s ease-out;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    outline: none;
    background-color: transparent;
}

img {
    border-style: none;
}

.nav-links-container {
    display: inline-block;
    margin-bottom: 0;
    line-height: 80px;
    margin-right: 40px;
}

.nav-links {
    color: var(--main-theme-color);
    text-transform: uppercase;
    font-size: var(--navbar-lrg-screen-text);
    letter-spacing: .15em !important;
    line-height: 1.5em;
    font-weight: normal;
    text-decoration: none;
    opacity: 0.75;

    padding: 1rem 1rem;
    height: 100%;
    overflow: hidden;
}

.nav-links:hover {
    transition: all 0.2s ease-out;
    color: var(--secondary-theme-color);
}

.nav-links-container:hover .nav-links {
    color: var(--secondary-theme-color);
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.new-feature::after {
    content: "New";
    color: #f9f9f9;
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: 20px;
    vertical-align: baseline;

    padding: 4px 14px;

    background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
    border-radius: 5px;
    box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.new-feature:focus {
    box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
}


/* Sub Nav + Sub Nav Link CSS */
.nav-links-container:hover>.subnav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    top: auto;
}

.has-categories {
    margin-left: -10%;
}


.subnav-container {
    display: none;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 8px 0px;

    margin-right: 5%;

    position: absolute;
    margin-top: -1%;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    padding-left: 0.5%;
    padding-right: 3%;

    line-height: 20px;
    min-height: 34px;
    text-transform: uppercase;

    div {
        margin-left: 5px;
        margin-right: 5px;
    }


    div>a:first-of-type {
        margin-top: 5%;
    }

    div>p:not(:empty) {
        padding: 8px 10px 0 30px;
        margin-bottom: .75em;
        font-size: var(--subnav-lrg-screen-text);
        font-weight: 600;
    }

    div>a {
        padding-bottom: 0 !important;
        height: auto !important;
        margin-bottom: 1em !important;

        color: #000;
        font-size: var(--subnav-lrg-screen-text);

        position: relative;
        display: block;
        width: 100%;
        padding: 8px 10px 0 30px;
        margin-bottom: .75em;
        border-bottom: 2px solid rgba(255, 255, 255, 0);
        transition: all 0.2s ease-out
    }

    div>a:hover {
        color: var(--secondary-theme-color);
    }

    div>a::before {
        position: absolute;
        left: -15px;
        content: '→';
        font-size: var(--subnav-arrow-lrg);
        display: block;
        opacity: 0;
        transition: all 0.2s ease-out
    }

    div>a:hover {
        padding-left: 2em !important
    }

    div>a:hover:before {
        opacity: 1;
        left: 1em
    }

    div>a:last-of-type {
        margin-bottom: 5%;
    }
}


/* Tablet / Laptop Displays */
@media screen and (min-width:941px) and (max-width: 1200px) {
    .NavbarItems {
        padding: 0px 0px 8px 0px !important;
        justify-content: space-between;
    }

    .nav-links-container {
        margin-right: 10px;
    }

    .nav-links {
        font-size: var(--navbar-nrm-screen-text);
    }

    .subnav-container {
        min-width: 120px;

        div>p:not(:empty) {
            font-size: var(--subnav-nrm-screen-text);

        }

        div>a {
            font-size: var(--subnav-nrm-screen-text);
        }

        div>a::before {
            position: absolute;
            left: -20px;
            content: '→';
            font-size: var(--subnav-arrow-nrm);
        }
    }

    .new-feature::after {
        font-size: 0.6rem;
    }
}


/* Mobile Displays */
@media screen and (max-width: 940px) {
    .NavbarItems {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;
        display: block;
        z-index: 300;
    }

    .nav-menu {
        display: none;
    }

    .nav-menu.active {
        background-color: #e9e9e9;
        height: auto !important;
        min-height: 1530px;
        margin: 0;
        width: 100%;
        display: block;
    }

    .nav-menu.active>ul {
        width: 90%;
        margin-left: 1em;
    }

    .nav-menu.active .subnav-container {
        display: block;
        top: auto;
        margin-left: 1em;
    }

    ul {
        padding-top: 10%;
    }

    .nav-links-container:first-of-type {
        margin-top: 10%;
    }

    .nav-links-container {
        display: block;
        justify-content: space-between;
        padding: 0px 30px 8px 0px;
        margin: 0;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .nav-links a {
        color: var(--main-theme-color);
        text-transform: uppercase;
        font-size: var(--navbar-sml-screen-text);
        letter-spacing: .15em !important;
        line-height: 1.5em;
        font-weight: normal;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
        filter: alpha(opacity=75);
        -moz-opacity: 0.75;
        -khtml-opacity: 0.75;
        opacity: 0.75
    }

    .nav-links img {
        display: none;
    }

    .subnav-container {
        position: relative;
        display: block !important;
        background-color: none;
        padding: 0px 0px 1.5em 0px !important;
        margin: 0px;
        border: 0px solid #eae7e2;
        border-radius: 0px;
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0);
        width: 100%;

        div>a:first-of-type {
            margin: 0;
        }

        div>a {
            padding: 0;
            line-height: 2em;
        }
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0%, -20%);
        vertical-align: baseline;
    }

    .menu-icon {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-30%, -5%);
        cursor: pointer;
        z-index: 201;
    }

    .subnav-container div {
        p:not(:empty) {
            padding: 8px 10px 0 0;
        }
    }

    .subitem-category-container:not(:first-of-type) {
        margin-top: 30px;
    }
}

/* Tablet Portrait */
@media only screen and (min-device-width: 500px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .nav-links-container:first-of-type {
        margin-top: 2%;
    }
}