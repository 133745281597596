.main-body {
    max-width: 1200px;
    margin: 0px auto;
    padding: 0px 16px 50px;
}

.main-body-heading {
    margin: 0px;
    font-size: 64px;
    line-height: 72px;
    font-weight: 500;
    padding: 120px 0px 100px;
}