#news-section {
    padding-bottom: 4em;
    position: relative;
    padding-top: 2em;
}

.news-section-title {
    display: block;
    padding: 48px 0px;
    max-width: 1800px;
    width: 100%;
    margin: auto;
}

.news-section-title-heading {
    font-size: 2rem;
    color: black;
    text-decoration: none;
    display: inline-block;
}

.news-section-title-heading:hover {
    color: var(--main-theme-color);
}

.news-section-title-heading::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--main-theme-color);
    transition: width .3s;
}

.news-section-title-heading:hover::after {
    width: 100%;
    transition: width .3s;
}

/*  Info-Cards Section  */

.news-infocards-container {
    display: inline;
    flex-direction: column;
    align-items: start;
    position: relative;
}

.news-infocards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 56px 44px;
    margin: 20px 0px 0px;
    list-style: none;
    padding: 0px;
}


.news-infocards>li:hover h2 {
    color: var(--main-theme-color);
}

.news-infocards li {

    padding: 1em;
    transition: all .15s ease-out;
}

.news-infocards li:hover {
    border: 3px solid var(--main-theme-color);
}

.news-infocard-item-container {
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    transition: color 0.25s ease 0s;

}

.news-infocard-item-container>h2 {
    margin: 0px;
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    transition: color 0.25s ease 0s;
    color: black;
}

.news-infocard-item-container>h2:hover {
    color: var(--main-theme-color);
}

.news-infocard-item-container>p:first-of-type {
    margin: 24px 0px 0px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--grey-color);
}

.news-infocard-item-container>p:last-of-type {
    margin: 24px 0px 0px;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-theme-color);
    font-weight: 500;
}

.news-infocard-item-caret {
    margin-left: 16px;
    vertical-align: middle;
}

.news-infocard-item-image-container {
    position: relative;
    width: 100%;
    height: 0px;
    margin-bottom: 40px;
    padding-top: calc(57.1429%);
}

.news-infocard-item-image-container>img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    object-fit: cover;
    border-style: none;
}

/* Mobile Displays */
@media screen and (max-width: 940px) {
    .news-infocards {
        grid-template-columns: none !important;
        justify-content: center;
    }
}