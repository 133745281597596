.course-page .left-column-body {
    .left-column-body-heading {
        margin: 0px;
        font-size: 54px;
        line-height: 72px;
        font-weight: 500;
    }
}

.left-body-content {
    hr {
        margin-top: 5%;
    }
}