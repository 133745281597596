.services-container {
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 2em 0;

    .service-category {
        margin-bottom: 2em;

        .service-description {
            font-size: 1rem;
            margin-bottom: 1em;
            text-transform: capitalize;

            p {
                font-size: 1.3rem;
            }
        }

        .service-item {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;

            .service-item-box {
                flex: 1;
                border: 1px solid #e9e9e9;
                border-radius: 8px;
                padding: 1em;
                background-color: #f9f9f9;
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
                min-width: 200px;
                text-transform: capitalize;
                margin: 10px 0;

                .new-feature::after {
                    margin-left: 0px !important;
                }
            }
        }

        .service-item-link {
            cursor: pointer;
            color: black;
            text-decoration: none;
            transition: color 0.2s;

            &:hover {
                color: var(--main-theme-color);
            }
        }
    }

    h1 {
        text-transform: capitalize;
    }

    h2 {
        font-size: 2rem;
        margin-bottom: 0.5em;
        text-transform: capitalize;
    }

    h3 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 0.5em;
        text-transform: capitalize;
    }

    p {
        font-size: 1.25rem;
        line-height: 1.5;
        text-transform: capitalize;
    }
}