.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;
  font-family: Gilroy, sans-serif;
  color: #333;

  h1 {
	font-size: 2.5em;
	font-weight: 600;
	color: var(--main-theme-color);
	margin-bottom: 0.5em;
  }

  h2 {
	font-size: 2em;
	font-weight: 500;
	margin-top: 1.5em;
	margin-bottom: 0.5em;
  }

  p {
	font-size: 1.125em;
	line-height: 1.6em;
	margin-bottom: 1em;
  }

  ul {
	list-style: none;
	padding: 0;

	li {
	  position: relative;
	  padding-left: 1.5em;
	  margin-bottom: 0.5em;
	  font-size: 1.125em;
	  line-height: 1.6em;

	  &:before {
		content: '•';
		position: absolute;
		left: 0;
		color: var(--secondary-theme-color);
	  }
	}
  }

  .highlight {
	font-weight: 600;
	color: var(--secondary-theme-color);
  }
}