.employee-details-container {
    overflow: auto;
    height: 72%;
    scrollbar-width: thin;
    scrollbar-color: #00707E #f1f1f1;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .popup-inner {
        position: relative;
        background-color: #fff;
        width: 70%;
        height: 60%;
        border-radius: 10px;

        .popup-heading {
            width: 100%;
            text-align: center;

            .employee-name {
                font-size: 28px;
                margin-bottom: 20px;
            }

            .employee-title {
                font-size: 20px;
                font-weight: normal;
                margin-left: 10px;
                color: var(--main-theme-color);
            }
        }
    }

    .popup-body {
        display: flex;
        justify-content: space-evenly;
        position: relative;
        height: 90%;

        .employee-image-container {
            margin-top: 0;
            margin-left: 2%;
            width: 40%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            overflow: hidden;
            border-radius: 10px 0 0 10px;

            .employee-image {
                margin-top: 20px;
                max-width: 100%;
                max-height: 70%;
                width: auto;
                height: auto;
                border-radius: 1%;
                object-fit: cover;
            }
        }

        .employee-info {
            width: 60%;
            padding: 20px;
            padding-top: 0;
            margin-right: 1%;
            padding-right: 10px;

            .employee-details-container {
                word-wrap: break-word;
                height: 90%;
            }

            .employee-details {
                width: 96%;
                font-size: 16px;
                line-height: 1.6;
                margin-right: 20px;
                margin-bottom: 10px;
                text-align: justify;
            }
        }

    }

    .close-btn {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 30px;
        cursor: pointer;
    }
}

/* Mobile + Tablet / Laptop Displays */
@media screen and (min-width: 426px) and (max-width: 1440px) {
    .popup-body {
        display: block !important;
        height: 85% !important;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;

        .employee-image-container {
            width: 100% !important;
            height: 35% !important;
            margin-left: 0 !important;

            .employee-image {
                max-height: 100% !important;
            }
        }
    }

    .popup-inner {
        width: 85% !important;
    }

    .employee-info {
        width: 98% !important;

        * {
            overflow-x: hidden !important;
        }
    }

    .employee-details {
        width: 94% !important;
    }


    .popup-inner {
        flex-direction: column;
        width: 80%;
        height: auto;

        .employee-image-container {
            width: 100%;
            height: 200px;
            border-radius: 10px 10px 0 0;

            overflow: visible !important;

            .employee-image {
                border-radius: 10px 0 0 10px;
            }
        }

        .employee-info {
            padding: 20px;

            .employee-name {
                margin-bottom: 20px;
            }

            .employee-details {
                margin-right: 0;
                margin-bottom: 20px;
                font-size: 13px !important;
            }
        }
    }

    .employee-details-container {
        margin-top: 5% !important;
    }
}


/* Mobile Display */
@media screen and (max-width: 425px) {
    .popup-body {
        display: block !important;
        height: 85% !important;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;

        .employee-image-container {
            width: 100% !important;
            height: 35% !important;
            margin-left: 0 !important;

            .employee-image {
                max-height: 100% !important;
            }
        }
    }

    .popup-inner {
        width: 85% !important;
    }

    .employee-info {
        width: 90% !important;

        * {
            overflow-x: hidden !important;
        }
    }

    .employee-details {
        width: 94% !important;
    }


    .popup-inner {
        flex-direction: column;
        width: 80%;
        height: auto;

        .employee-image-container {
            width: 100%;
            height: 200px;
            border-radius: 10px 10px 0 0;

            overflow: visible !important;

            .employee-image {
                border-radius: 10px 0 0 10px;
            }
        }

        .employee-info {
            padding: 20px;

            .employee-name {
                margin-bottom: 20px;
            }

            .employee-details {
                margin-right: 0;
                margin-bottom: 20px;
                font-size: 13px !important;
            }
        }
    }

    .employee-details-container {
        margin-top: 5% !important;
    }
}