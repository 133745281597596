.subheading-description {
  padding-bottom: 80px;
}

.subheading-description p {
  font-size: var(--main-text);
  text-align: justify;
}

.popup-container {
  display: none;
  width: 40vw;
  height: 50vh;
  position: relative;
  z-index: 41;
  top: 25%;
  padding: 30px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 90px 20px;
  padding-bottom: 90px;
}

.row-item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.row-item div h2 {
  font-size: var(--subheading-text);
}

.row-item div h3 {
  font-size: var(--main-text);
}

.row-item:hover {
  color: var(--main-theme-color);
  cursor: pointer;
}

.image-container img {
  margin-bottom: 5px;
  width: 100%;
  border-radius: 0px;
}

.image-container:after {
  border-bottom: 4px solid var(--main-theme-color);
  content: '';
  display: block;
  margin-bottom: 5px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.image-container:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

/* Popup CSS */
.popup-content {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.hamburger-menu {
  cursor: pointer;
}

.modal {
  font-size: var(--main-text);
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
  background: #fff;
  border-radius: 20px;
}

.header-container {
  position: relative;
  display: inline-block;
}

.close-container {
  position: absolute;
  top: 400px;
  right: -30px;
  margin-top: 3px;
}

.close-container2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.popup-content-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 30px;
}

.popup-content-container .header h2 {
  font-size: 2.5rem;
}

.header {
  width: 100%;
  border-bottom: 1px solid gray;
  text-align: center;
}

.popup-text-content {
  width: 100%;
  padding: 20px 5px;
}

.popup-content-container > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 65px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

@media only screen and (max-width: 940px) {
  .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;
  }
  .row-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 80%;
            flex: 0 0 80%;
    max-width: 80%;
  }
  .subheading-description {
    margin-left: 10%;
    margin-right: 10%;
  }
  .subheading-description h3 {
    line-height: 30px;
    font-size: 18px;
  }
}
