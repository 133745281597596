@charset "UTF-8";
.two-column-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 50vh;
  padding-top: 100px;
  padding-bottom: 100px;
}

.left-column {
  max-width: 700px;
  margin-right: 53px;
}

.left-column h1 {
  font-size: 45px;
}

.left-column .stylised-text {
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#006847), to(#00ba7f));
  background: linear-gradient(to right, #006847, #00ba7f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.right-column {
  margin-right: 5%;
  position: relative;
  width: 400px;
  height: 300px;
}

.right-column div {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.right-column div img {
  height: 100%;
  width: 480px;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  background-color: transparent;
  border-radius: 0.5rem;
  border-bottom: 0 solid var(--main-theme-color);
  -webkit-transition: border-bottom 300ms ease-in-out, padding-bottom 300ms ease-in-out;
  transition: border-bottom 300ms ease-in-out, padding-bottom 300ms ease-in-out;
}

.right-column img:hover {
  padding-bottom: 0.5rem;
  border-bottom: 0.5rem solid var(--main-theme-color);
}

.right-column img > :first-child {
  margin-top: 0;
}

.right-column img > :last-child {
  margin-bottom: 0;
}

/* This only changes this particular animation duration */
.animate__animated.animate__fadeInUp {
  --animate-duration: 1s;
}

.body-section-container {
  padding-bottom: 120px;
}

.body-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: 10px;
  padding-bottom: 90px;
}

.body-column {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.body-column .column-text {
  font: 20px/30px Gilroy;
  position: relative;
  z-index: 1;
  max-width: 552px;
}

.objectives {
  width: 100%;
}

.objectives ul {
  margin-top: 5%;
  list-style-type: none;
  padding-left: 0;
  font-size: 18px;
  line-height: 1.6;
  width: 100%;
}

.objectives li {
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.objectives li::before {
  content: "•";
  color: var(--secondary-theme-color);
  font-size: 25px;
  position: absolute;
  left: 0px;
  top: 0;
}

.objectives strong {
  font-size: 25px;
  color: var(--secondary-theme-color);
}

.body-column ul {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
}

.body-column li {
  margin-bottom: 15px;
  padding-left: 10px;
  position: relative;
}

.body-column li::before {
  content: "•";
  color: var(--main-theme-color);
  font-size: 24px;
  position: absolute;
  left: -20px;
  top: 0;
}

.body-column strong {
  color: var(--main-theme-color);
}

.highlight {
  font-weight: 600;
  color: var(--secondary-theme-color);
}

.values-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  /* Change to column to stack items vertically */
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  /* Center items horizontally */
  margin-left: 0;
  /* Adjust margins as needed */
  margin-right: 0;
  /* Adjust margins as needed */
}

.value-item h2 {
  font-size: 25px;
}

.value-item p {
  font-size: var(--main-text);
}

.section-header {
  margin-top: 0;
  font-size: 40px;
  max-width: 450px;
}

.apt-numbers {
  margin-bottom: 80px;
}

.apt-numbers .stats-title-top {
  border-top: 1px solid #e2e2e2;
}

.apt-numbers .stats-title-top h2 {
  line-height: 1px;
  font-size: var(--subheading-text);
}

.apt-numbers .stats-title {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 48px;
  padding: 32px 0;
}

.stats-row {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}

.stats-item:nth-child(4n-3) {
  border-left: none;
}

.stats-item {
  border-left: 1px solid #e2e2e2;
  padding: 16px 103px 32px 24px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 14%;
          flex: 0 0 14%;
  max-width: 14%;
}

.stats-item .stats-number {
  color: var(--main-theme-color);
  line-height: 46px;
  font-size: 40px/56px;
  margin: 0;
}

.stats-item .stats-description {
  font-size: 13px;
}

.stats-description {
  margin-bottom: 0;
  color: #333;
  font: 500 14px/22px;
}

@media only screen and (min-width: 941px) and (max-width: 1200px) {
  .stats-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12%;
            flex: 0 0 12%;
    max-width: 12%;
  }
}

@media only screen and (max-width: 940px) {
  .body-section-container {
    width: calc(100% - 34px);
    padding: 0;
    margin: 0;
  }
  .two-column-container {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
    padding-bottom: 0;
  }
  .left-column {
    max-width: 100% !important;
    width: 100%;
    margin-right: 0px !important;
  }
  .left-column h1 {
    text-align: center;
  }
  .right-column {
    display: none;
  }
  .stats-item:nth-child(even) {
    padding-left: 16px;
    padding-right: 24px;
  }
  .stats-item:nth-child(odd) {
    padding-right: 16px;
  }
  .stats-row {
    gap: 10px;
  }
  .stats-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 49%;
    border-left: none;
    padding: 16px 103px 32px 24px !important;
    position: relative;
    width: 100%;
  }
  .apt-numbers {
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
  }
  .body-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0;
    width: 95vw;
  }
  .body-row .body-column {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .body-row .body-column:first-child {
    text-align: center;
  }
  .section-header {
    max-width: 100%;
  }
  .values-container {
    width: 100vw;
    padding-bottom: 100px;
  }
  .body-column .column-text {
    max-width: 100%;
    text-align: justify;
  }
}
