
.slideItem {
    /*margin-left: 30px;*/
    margin-right: 30px;

    justify-content: center !important;
    align-items: center !important;
    height: 100px !important;
    display: flex !important;
}

.slick-prev:before{
    color: black !important;
}

.slick-next:before{
    color: black !important;
}


.partner-logo {
    width: 90px;
    height: auto;
    filter: grayscale(100%);
}

.partner-logo:hover {
    filter: none;
}