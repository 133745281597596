@charset "UTF-8";
.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;
  font-family: Gilroy, sans-serif;
  color: #333;
}

.content-container h1 {
  font-size: 2.5em;
  font-weight: 600;
  color: var(--main-theme-color);
  margin-bottom: 0.5em;
}

.content-container h2 {
  font-size: 2em;
  font-weight: 500;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.content-container p {
  font-size: 1.125em;
  line-height: 1.6em;
  margin-bottom: 1em;
}

.content-container ul {
  list-style: none;
  padding: 0;
}

.content-container ul li {
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0.5em;
  font-size: 1.125em;
  line-height: 1.6em;
}

.content-container ul li:before {
  content: '•';
  position: absolute;
  left: 0;
  color: var(--secondary-theme-color);
}

.content-container .highlight {
  font-weight: 600;
  color: var(--secondary-theme-color);
}
