/*  Mobile Hamburger Menu  */
.hamburger-svg {
    height: 80px;
    position: absolute;
    width: 80px;
}

.hamburger-menu {
    z-index: 100;
    height: 80px;
    width: 80px;
}

.burger {
    -webkit-filter: url(#burger-effect);
    filter: url(#burger-effect);
}

.x {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 400ms;
    transition: -webkit-transform 400ms;
    transition: transform 400ms;
    transition: transform 400ms, -webkit-transform 400ms;
}

.line {
    fill: none;
    stroke: var(--hamburger-color);    /* Sets colour of Hamburger Menu Lines */
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, -webkit-transform 500ms 200ms;
    transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, -webkit-transform 500ms 200ms;
    transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, transform 500ms 200ms;
    transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, transform 500ms 200ms, -webkit-transform 500ms 200ms;
}

.x .line {
    stroke-width: 5.5px;
}

.active .x {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 400ms 350ms;
    transition: -webkit-transform 400ms 350ms;
    transition: transform 400ms 350ms;
    transition: transform 400ms 350ms, -webkit-transform 400ms 350ms;
}

/* El segundo plato */
.hamburger-menu .line1 {
    stroke-dasharray: 21 185.62753295898438;
    -webkit-transition-delay: 0;
    transition-delay: 0;
}

.hamburger-menu .line2 {
    stroke-dasharray: 21 178.6514129638672;
    -webkit-transition-delay: 30ms;
    transition-delay: 30ms;
}

.hamburger-menu .line3 {
    stroke-dasharray: 21 197.92425537109375;
    -webkit-transition-delay: 60ms;
    transition-delay: 60ms;
}

.hamburger-menu .line4 {
    stroke-dasharray: 21 190.6597137451172;
    -webkit-transition-delay: 90ms;
    transition-delay: 90ms;
}

.hamburger-menu .line5 {
    stroke-dasharray: 21 208.52874755859375;
    -webkit-transition-delay: 120ms;
    transition-delay: 120ms;
}

.hamburger-menu .line6 {
    stroke-dasharray: 21 186.59703063964844;
    -webkit-transition-delay: 150ms;
    transition-delay: 150ms;
}

.active.hamburger-menu .line1 {
    stroke-dasharray: 5 185.62753295898438;
    stroke-dashoffset: -141px;
}

.active.hamburger-menu .line2 {
    stroke-dasharray: 5 178.6514129638672;
    stroke-dashoffset: -137px;
}

.active.hamburger-menu .line3 {
    stroke-dasharray: 5 197.92425537109375;
    stroke-dashoffset: -176px;
}

.active.hamburger-menu .line4 {
    stroke-dasharray: 5 190.6597137451172;
    stroke-dashoffset: -159px;
}

.active.hamburger-menu .line5 {
    stroke-dasharray: 5 208.52874755859375;
    stroke-dashoffset: -139px;
}

.active.hamburger-menu .line6 {
    stroke-dasharray: 5 186.59703063964844;
    stroke-dashoffset: -176px;
}

.active.hamburger-menu .x {
    -webkit-transition: -webkit-transform 400ms 250ms;
    transition: -webkit-transform 400ms 250ms;
    transition: transform 400ms 250ms;
    transition: transform 400ms 250ms, -webkit-transform 400ms 250ms;
}

.hamburger-links.active {
    display: block;
    position: absolute;
    z-index: -2;
    width: 100vw;
    height: 100vh;
    background: -webkit-gradient(linear, left top, left bottom, from(#323232), color-stop(40%, #3F3F3F), color-stop(150%, #1C1C1C)), -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.4)), color-stop(200%, rgba(0, 0, 0, 0.25)));
    background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.25) 200%);
    background-blend-mode: multiply;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}

.hamburger-links.active div {
    width: 100vw;
    padding-top: 50%;
    text-align: end;
}

.hamburger-links.active div a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: right;
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
    padding: 0px 20px 20px 32px;
    text-decoration: none;
    color: #818181;
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 3rem;
    font-family: Avenir;
}

/*  Animation keyframes  */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}