.subheading-description {
    padding-bottom: 80px;

    p {
        font-size: var(--main-text);
        text-align: justify;
    }
}

.popup-container {
    display: none;
    width: 40vw;
    height: 50vh;
    position: relative;
    z-index: 41;
    top: 25%;
    padding: 30px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

    display: flex;
    flex-direction: row;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-between;

    gap: 90px 20px;

    padding-bottom: 90px;
}

.row-item {
    flex: 0 0 25%;
    max-width: 25%;

    div {
        h2 {
            font-size: var(--subheading-text);
        }

        h3 {
            font-size: var(--main-text);
        }
    }
}

.row-item:hover {
    color: var(--main-theme-color);
    cursor: pointer;
}

.image-container {
    img {
        margin-bottom: 5px;
        width: 100%;
        border-radius: 0px;
    }
}

.image-container:after {
    border-bottom: 4px solid var(--main-theme-color);
    content: '';
    display: block;
    margin-bottom: 5px;
    transform: scaleX(0);
    transition: transform 0.5s ease-in-out;
}

.image-container:hover:after {
    transform: scaleX(1);
}


/* Popup CSS */
.popup-content {
    backdrop-filter: blur(10px);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hamburger-menu {
    cursor: pointer;
}

.modal {
    font-size: var(--main-text);
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;

    background: #fff;
    border-radius: 20px;
}

.header-container {
    position: relative;
    display: inline-block;
}

.close-container {
    position: absolute;
    top: 400px;
    right: -30px;
    margin-top: 3px;
}

.close-container2 {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.popup-content-container {
    height: fit-content;
    padding: 30px;

    .header {
        h2 {
            font-size: 2.5rem;
        }
    }
}


.header {
    width: 100%;
    border-bottom: 1px solid gray;
    text-align: center;
}

.popup-text-content {
    width: 100%;
    padding: 20px 5px;
}

.popup-content-container>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 65px;

    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}



@media only screen and (max-width: 940px) {
    .row {
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .row-item {
        flex: 0 0 80%;
        max-width: 80%
    }

    .subheading-description {
        margin-left: 10%;
        margin-right: 10%;

        h3 {
            line-height: 30px;
            font-size: 18px;
        }
    }
}