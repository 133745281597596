.course-page-wrapper {
  padding: 40px 0;
}

.course-page-wrapper .course-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.course-page-wrapper .course-page .course-page-heading {
  text-align: left;
  font-size: 55px;
  font-weight: 500;
  margin-left: 40px;
  margin-bottom: 40px;
}

.course-page-wrapper .course-page .main-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px 20px;
}

.course-page-wrapper .course-page .main-body .left-body-content {
  width: 70%;
  padding: 20px;
  padding-top: 0px;
  padding-right: 10%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.course-page-wrapper .course-page .main-body .left-body-content h3 {
  font-size: 1.7rem;
}

.course-page-wrapper .course-page .main-body .left-body-content p {
  font-size: 1.2rem;
  padding-bottom: 16px;
}

.course-page-wrapper .course-page .main-body .left-body-content ul {
  padding-left: 20px;
}

.course-page-wrapper .course-page .main-body .right-body-content {
  width: 30%;
  padding: 20px;
  margin-top: 5%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 90%;
}

.course-page-wrapper .next-steps-div {
  margin-left: 40px;
  margin-bottom: 40px;
  padding-top: 1%;
  padding-bottom: 2%;
  padding-left: 3%;
  padding-right: 3%;
  background: linear-gradient(135deg, #00707E, #4B9EB1, #E5D9F2, #F5EFFF);
  background-size: 400% 400%;
  -webkit-animation: gradient-animation 8s ease infinite;
          animation: gradient-animation 8s ease infinite;
  border-radius: 11px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

.course-page-wrapper .next-steps-div h3 {
  color: black;
}

.course-page-wrapper .next-steps-div p {
  color: black;
}

.course-page-wrapper .next-steps-div .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  margin-top: 20px;
}

.course-page-wrapper .next-steps-div .button-container .contact-button {
  text-align: center;
  width: 45%;
  padding: 15px 20px;
  background-color: #262626;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  border-radius: 11px;
  text-decoration: none;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.course-page-wrapper .next-steps-div .button-container .contact-button:hover {
  background-color: #333333;
}

@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
