.two-column-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 50vh;

    padding-top: 100px;
    padding-bottom: 100px;
}

.left-column {
    max-width: 700px;
    margin-right: 53px;

    h1 {
        font-size: 45px;
    }

    .stylised-text {
        width: 100%;
        background: linear-gradient(to right, #006847, #00ba7f);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.right-column {
    margin-right: 5%;

    position: relative;
    width: 400px;
    height: 300px;

    div {
        width: 100%;
        height: fit-content;

        img {
            height: 100%;
            width: 480px;

            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
                0 5px 15px 0 rgba(0, 0, 0, 0.08);

            background-color: transparent;
            border-radius: 0.5rem;

            border-bottom: 0 solid var(--main-theme-color);
            transition: border-bottom 300ms ease-in-out, padding-bottom 300ms ease-in-out;
        }
    }

    img:hover {
        padding-bottom: 0.5rem;
        border-bottom: 0.5rem solid var(--main-theme-color);
    }

    img> :first-child {
        margin-top: 0;
    }

    img> :last-child {
        margin-bottom: 0;
    }
}


/* This only changes this particular animation duration */
.animate__animated.animate__fadeInUp {
    --animate-duration: 1s;
}

.body-section-container {
    padding-bottom: 120px;
}

.body-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: 10px;

    padding-bottom: 90px;
}

.body-column {
    flex: 0 0 50%;
    max-width: 50%;

    .column-text {
        font: 20px/30px Gilroy;
        position: relative;
        z-index: 1;
        max-width: 552px;
    }
}

.objectives {
    width: 100%;

    ul {
        margin-top: 5%;
        list-style-type: none;
        padding-left: 0; // Remove padding to use full width
        font-size: 18px;
        line-height: 1.6;
        width: 100%; // Ensure ul takes full width
    }

    li {
        margin-bottom: 15px;
        padding-left: 30px; // Adjust padding for custom bullet points
        position: relative;
        width: 100%; // Ensure li takes full width
        box-sizing: border-box; // Include padding in width calculation
    }

    li::before {
        content: "•";
        color: var(--secondary-theme-color);
        font-size: 25px;
        position: absolute;
        left: 0px; // Adjust position for custom bullet points
        top: 0;
    }

    strong {
        font-size: 25px;
        color: var(--secondary-theme-color);
    }
}

.body-column {

    // Add this new styling for the 'Our Objectives' section
    ul {
        list-style-type: disc;
        padding-left: 20px;
        font-size: 18px;
        line-height: 1.6;
        color: #333;
    }

    li {
        margin-bottom: 15px;
        padding-left: 10px;
        position: relative;
    }

    li::before {
        content: "•";
        color: var(--main-theme-color);
        font-size: 24px;
        position: absolute;
        left: -20px;
        top: 0;
    }

    strong {
        color: var(--main-theme-color);
    }
}

.highlight {
    font-weight: 600;
    color: var(--secondary-theme-color);
}

.values-container {
    display: flex;
    flex-direction: column;
    /* Change to column to stack items vertically */
    align-items: flex-start;
    /* Center items horizontally */
    margin-left: 0;
    /* Adjust margins as needed */
    margin-right: 0;
    /* Adjust margins as needed */
}

.value-item {

    h2 {
        font-size: 25px;
    }

    p {
        font-size: var(--main-text);
    }
}

.section-header {
    margin-top: 0;
    font-size: 40px;
    max-width: 450px;
}

.apt-numbers {
    margin-bottom: 80px;

    .stats-title-top {
        border-top: 1px solid #e2e2e2;

        h2 {
            line-height: 1px;
            font-size: var(--subheading-text);
        }
    }

    .stats-title {
        border-bottom: 1px solid #e2e2e2;
        margin-bottom: 48px;
        padding: 32px 0;
    }
}

.stats-row {
    flex-wrap: wrap;
    width: 100%;
}

.stats-item:nth-child(4n-3) {
    border-left: none;
}

.stats-item {
    border-left: 1px solid #e2e2e2;
    padding: 16px 103px 32px 24px;
    flex: 0 0 14%;
    max-width: 14%;

    .stats-number {
        color: var(--main-theme-color);
        line-height: 46px;
        font-size: 40px/56px;
        margin: 0;
    }

    .stats-description {
        font-size: 13px;
    }
}

.stats-description {
    margin-bottom: 0;
    color: #333;
    font: 500 14px/22px;
}

@media only screen and (min-width: 941px) and (max-width: 1200px) {
    .stats-item {
        flex: 0 0 12%;
        max-width: 12%;

    }
}

@media only screen and (max-width: 940px) {
    .body-section-container {
        width: calc(100% - 34px);
        padding: 0;
        margin: 0;
    }

    .two-column-container {
        flex-direction: column !important;
        padding-bottom: 0;
    }

    .left-column {
        max-width: 100% !important;
        width: 100%;
        margin-right: 0px !important;

        h1 {
            text-align: center;
        }

    }

    .right-column {
        display: none;

    }


    .stats-item:nth-child(even) {
        padding-left: 16px;
        padding-right: 24px;
    }

    .stats-item:nth-child(odd) {
        padding-right: 16px;
    }

    .stats-row {
        gap: 10px;

    }

    .stats-item {
        flex: 0 0 30%;
        flex-grow: 1;
        max-width: 49%;

        border-left: none;
        padding: 16px 103px 32px 24px !important;

        position: relative;
        width: 100%;
    }

    .apt-numbers {
        padding: 0;
        margin: 0;
        margin-bottom: 8px;
    }

    .body-row {
        flex-direction: column;
        margin: 0;
        width: 95vw;

        .body-column {
            flex: 0 0 100%;
            max-width: 100%;
            margin-left: 20px;
            margin-right: 20px;
        }

        .body-column:first-child {
            text-align: center;
        }
    }

    .section-header {
        max-width: 100%;
    }

    .values-container {
        width: 100vw;
        padding-bottom: 100px;
    }

    .body-column .column-text {
        max-width: 100%;
        text-align: justify;
    }
}