.index-container {
    width: 100%;
    overflow: hidden;
    padding: 6em 4em;
    box-sizing: border-box;
    padding-bottom: 2em !important;
    color: #212121;
}

/* General Styling */
.main-body {
    margin: 0px auto;
    padding: 0px 16px 50px;
}

.main-body-heading {
    margin: 0px;
    font-size: 64px;
    line-height: 72px;
    font-weight: 500;
    padding: 120px 0px 100px;
}

@media screen and (min-width: 1921px){
    .main-body {
        max-width: 1800px !important;
    }
}