.right-body-content {
    width: 30%;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;

    .course-summary {
        padding: 25px 25px 25px 20px;
        background-color: var(--main-theme-color);
        color: white;
        border-radius: 8px 8px 0 0;

        /* Add glow effect */
        box-shadow: 0 8px 15px rgba(var(--main-theme-rgb), 0.4);
    }

    .content-wrapper {
        padding: 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .summary-item {
            h4 {
                font-size: 1.4rem;
            }

            p {
                font-size: 1.2rem;
            }
        }
    }
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 20px;

    .rounded-button {
        width: 100%;
        padding: 15px 20px;
        font-size: 1.4rem;
        background-color: var(--secondary-theme-color);
        color: white;
        border-radius: 11px;
        text-decoration: none;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease, transform 0.2s ease;

        &:hover {
            background-color: var(--secondary-theme-color);
            opacity: 0.9;
            transform: scale(1.05);
        }
    }
}