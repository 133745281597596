.employee-details-container {
  overflow: auto;
  height: 72%;
  scrollbar-width: thin;
  scrollbar-color: #00707E #f1f1f1;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1;
}

.popup .popup-inner {
  position: relative;
  background-color: #fff;
  width: 70%;
  height: 60%;
  border-radius: 10px;
}

.popup .popup-inner .popup-heading {
  width: 100%;
  text-align: center;
}

.popup .popup-inner .popup-heading .employee-name {
  font-size: 28px;
  margin-bottom: 20px;
}

.popup .popup-inner .popup-heading .employee-title {
  font-size: 20px;
  font-weight: normal;
  margin-left: 10px;
  color: var(--main-theme-color);
}

.popup .popup-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  position: relative;
  height: 90%;
}

.popup .popup-body .employee-image-container {
  margin-top: 0;
  margin-left: 2%;
  width: 40%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  overflow: hidden;
  border-radius: 10px 0 0 10px;
}

.popup .popup-body .employee-image-container .employee-image {
  margin-top: 20px;
  max-width: 100%;
  max-height: 70%;
  width: auto;
  height: auto;
  border-radius: 1%;
  -o-object-fit: cover;
     object-fit: cover;
}

.popup .popup-body .employee-info {
  width: 60%;
  padding: 20px;
  padding-top: 0;
  margin-right: 1%;
  padding-right: 10px;
}

.popup .popup-body .employee-info .employee-details-container {
  word-wrap: break-word;
  height: 90%;
}

.popup .popup-body .employee-info .employee-details {
  width: 96%;
  font-size: 16px;
  line-height: 1.6;
  margin-right: 20px;
  margin-bottom: 10px;
  text-align: justify;
}

.popup .close-btn {
  position: fixed;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 30px;
  cursor: pointer;
}

/* Mobile + Tablet / Laptop Displays */
@media screen and (min-width: 426px) and (max-width: 1440px) {
  .popup-body {
    display: block !important;
    height: 85% !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }
  .popup-body .employee-image-container {
    width: 100% !important;
    height: 35% !important;
    margin-left: 0 !important;
  }
  .popup-body .employee-image-container .employee-image {
    max-height: 100% !important;
  }
  .popup-inner {
    width: 85% !important;
  }
  .employee-info {
    width: 98% !important;
  }
  .employee-info * {
    overflow-x: hidden !important;
  }
  .employee-details {
    width: 94% !important;
  }
  .popup-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 80%;
    height: auto;
  }
  .popup-inner .employee-image-container {
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
    overflow: visible !important;
  }
  .popup-inner .employee-image-container .employee-image {
    border-radius: 10px 0 0 10px;
  }
  .popup-inner .employee-info {
    padding: 20px;
  }
  .popup-inner .employee-info .employee-name {
    margin-bottom: 20px;
  }
  .popup-inner .employee-info .employee-details {
    margin-right: 0;
    margin-bottom: 20px;
    font-size: 13px !important;
  }
  .employee-details-container {
    margin-top: 5% !important;
  }
}

/* Mobile Display */
@media screen and (max-width: 425px) {
  .popup-body {
    display: block !important;
    height: 85% !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }
  .popup-body .employee-image-container {
    width: 100% !important;
    height: 35% !important;
    margin-left: 0 !important;
  }
  .popup-body .employee-image-container .employee-image {
    max-height: 100% !important;
  }
  .popup-inner {
    width: 85% !important;
  }
  .employee-info {
    width: 90% !important;
  }
  .employee-info * {
    overflow-x: hidden !important;
  }
  .employee-details {
    width: 94% !important;
  }
  .popup-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 80%;
    height: auto;
  }
  .popup-inner .employee-image-container {
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
    overflow: visible !important;
  }
  .popup-inner .employee-image-container .employee-image {
    border-radius: 10px 0 0 10px;
  }
  .popup-inner .employee-info {
    padding: 20px;
  }
  .popup-inner .employee-info .employee-name {
    margin-bottom: 20px;
  }
  .popup-inner .employee-info .employee-details {
    margin-right: 0;
    margin-bottom: 20px;
    font-size: 13px !important;
  }
  .employee-details-container {
    margin-top: 5% !important;
  }
}
