.testimonial-quote-container {
    padding-top: 5%;
    font-size: 18px;
    text-align: left;


    width: 80%;
}

.testimonial-content {
    font-size: 25px;
    margin-bottom: 5%;
    font-family: Garamond;
    text-align: center;
}

.testimonial-content span {
    font-size: 13px;
    text-align: left;
    font-family: Gilroy;
    font-weight: normal;
    font-style: italic;
    line-height: 10px;
}

.testimonial-author {
    font-size: 16px;

    margin: 0;

    text-align: right;
    font-weight: bold;
    color: var(--main-theme-color);
}


.fade-in {
    transition: opacity 1s ease;
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}


@media screen and (min-width: 941px) and (max-width: 1200px) {
    .testimonial-quote-container {
        padding-top: 10%;
    }

    .testimonial-content {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 940px) {
    .testimonial-quote-container {
        width: 100%;
    }

    .testimonial-content {
        font-size: 30px;
        text-align: left;
        margin-bottom: 0;
    }

    .testimonial-content span {
        font-size: 18px;
        text-align: left;
    }

    .testimonial-author {
        text-align: left;
    }
}