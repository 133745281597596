.services-container {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 2em 0;
}

.services-container .service-category {
  margin-bottom: 2em;
}

.services-container .service-category .service-description {
  font-size: 1rem;
  margin-bottom: 1em;
  text-transform: capitalize;
}

.services-container .service-category .service-description p {
  font-size: 1.3rem;
}

.services-container .service-category .service-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1em;
}

.services-container .service-category .service-item .service-item-box {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 1em;
  background-color: #f9f9f9;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  min-width: 200px;
  text-transform: capitalize;
  margin: 10px 0;
}

.services-container .service-category .service-item .service-item-box .new-feature::after {
  margin-left: 0px !important;
}

.services-container .service-category .service-item-link {
  cursor: pointer;
  color: black;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.services-container .service-category .service-item-link:hover {
  color: var(--main-theme-color);
}

.services-container h1 {
  text-transform: capitalize;
}

.services-container h2 {
  font-size: 2rem;
  margin-bottom: 0.5em;
  text-transform: capitalize;
}

.services-container h3 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5em;
  text-transform: capitalize;
}

.services-container p {
  font-size: 1.25rem;
  line-height: 1.5;
  text-transform: capitalize;
}
