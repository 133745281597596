:root {
    --bg-color: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    --navbar-bg-color: transparent;
    --main-theme-color: #00707E;
    --secondary-theme-color: #4B9EB1;
    --hamburger-color: #00707E;
    --grey-color: #636e72;
    --midnight-blue-color: #2c3e50;
    --complimentary-theme-color: #00707E;

    /* Font Sizes */
    --main-text: 18px;
    --subheading-text: 20px;

    --navbar-lrg-screen-text: 16px;
    --navbar-nrm-screen-text: 14px;
    --navbar-sml-screen-text: 12px;

    --subnav-lrg-screen-text: 16px;
    --subnav-arrow-lrg: 14px;

    --subnav-nrm-screen-text: 14px;
    --subnav-arrow-nrm: 12px;

    --subnav-sml-screen-text: 12px;




    /* Legal Vars */
    --legal-font-size: 16px;
    --legal-line-height: 20px;
    --legal-font-weight: 400;
    --legal-font-family: "Concourse T3";
}