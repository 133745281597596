.right-body-content {
  width: 30%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

.right-body-content .course-summary {
  padding: 25px 25px 25px 20px;
  background-color: var(--main-theme-color);
  color: white;
  border-radius: 8px 8px 0 0;
  /* Add glow effect */
  -webkit-box-shadow: 0 8px 15px rgba(var(--main-theme-rgb), 0.4);
          box-shadow: 0 8px 15px rgba(var(--main-theme-rgb), 0.4);
}

.right-body-content .content-wrapper {
  padding: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.right-body-content .content-wrapper .summary-item h4 {
  font-size: 1.4rem;
}

.right-body-content .content-wrapper .summary-item p {
  font-size: 1.2rem;
}

.button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
  padding-bottom: 20px;
}

.button-container .rounded-button {
  width: 100%;
  padding: 15px 20px;
  font-size: 1.4rem;
  background-color: var(--secondary-theme-color);
  color: white;
  border-radius: 11px;
  text-decoration: none;
  text-align: center;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.3s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.3s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
}

.button-container .rounded-button:hover {
  background-color: var(--secondary-theme-color);
  opacity: 0.9;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
